@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fefefe;
  font-family: "Roboto", sans-serif;
}

.c_navbar {
  height: 40px;
  padding: 0px 2rem;
  background-color: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c_nav_l {
  display: flex;
  width: max-content;
}
.c_nav_l {
  display: flex;
  width: max-content;
}

.alert-process {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 50px;
  background-color: rgba(144, 238, 144, 1);
  color: green;
  font-size: 0.8rem;
  font-style: italic;
  border: 1px solid green;
  border-radius: 25px;
  z-index: 999999;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.c_main {
  height: calc(100vh - 65px);
  overflow-y: auto;
}

.sect-dataset-keyword-information {
  background-color: #cff4fc;
  text-align: center;
  font-size: 14px;
  padding: 2px;
  color: #055160;
}

.sect_keyword {
  height: calc(100vh - 65px);
  padding: 1rem 2rem;
  /* background-color: red; */
}

.sect_mapper {
  height: calc(100vh - 65px);
  padding: 1rem;
  /* background-color: lightblue; */
}

.c_footer {
  height: 25px;
  font-size: 0.7rem;
  padding: 0px 2rem;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c_center {
  display: flex;
  justify-content: center;
}

.accordion-button {
  padding: 4px !important;
}

.accordion {
  --bs-accordion-btn-icon-width: 0.8rem !important;
}

.accordion-body {
  padding: 2px !important;
}

.box-keyword {
  min-height: 500px;
  height: 80vh;
  width: 100%;
  background-color: #efefef;
}

.box-keyword-top {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fefefe;
}

.box_keyword_navigation {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.box_btn_keyword_navigation {
  display: flex;
  margin-bottom: 5px;
  padding-right: 5px;
}

.c_btn_prev,
.c_btn_next {
  width: max-content;
}

.box-keyword-main {
  width: calc(100% - 100px);
  background-color: #fefefe;
  /* background-color: lightgray; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-keyword-img {
  width: 100%;
  height: calc(100% - 70px);
  overflow-x: auto;
  display: flex;
  border: 4px solid #efefef;
  align-items: center;
}

.b_icon {
  color: #aaa;
  font-size: 20px;
  cursor: pointer;
  transition: all ease-in-out 0.1s;
}

.b_icon:hover {
  color: limegreen;
}

.b_icon_disabled {
  color: #eee;
  font-size: 20px;
  cursor: not-allowed;
  transition: all ease-in-out 0.1s;
}

.k_amount_info {
  font-size: 11px;
  margin-right: 5px;
}

.b_icon_trash_small {
  font-size: 12px;
  color: red;
}

.pvw_img {
  height: 95%;
  max-height: 400px;
  max-width: 80vw;
  /* width: 600px; */
  margin: 4px;
}

.vertical-image-container {
  width: 100%;
  height: 65vh;
  overflow-y: auto;
}

.vertical_pvw_img {
  /* height: 95%;
  max-height: 400px; */
  width: 100%;
  /* max-width: 600px; */
}

.button-box {
  width: max-content;
  height: max-content;
  white-space: nowrap;
}

.c_modal_header {
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.c_pointer {
  cursor: pointer;
}

.main_mapper {
  height: 100%;
}
.main_mapper_title {
  height: 50px;
  display: flex;
}

.mapper_btn_box {
  padding-left: 1rem;
}

.mapper_title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-map {
  height: calc(100% - 50px);
  width: 100%;
  /* background-color: lightcyan; */
  overflow-y: auto;
}

.box-map-items-container {
  display: flex;
  flex-wrap: wrap;
}

.box-map-item {
  height: 400px;
  padding: 4px;
  width: calc(100% / 3);
}

.map-item {
  padding: 8px;
  background-color: #efefef;
  height: 100%;
  width: 100%;
  overflow: auto;
  border-radius: 12px;
}

.c_hover {
  background-color: transparent;
  animation: all 0.2s ease-in-out;
}

.c_hover:hover {
  background-color: #f5f5f5;
}

.map-item-children {
  margin-left: 25px;
  position: relative;
}

.map-item-children-itm {
  display: flex;
  align-items: center;
  padding: 3px 0px;
  padding-right: 30px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  min-width: max-content;
}

.map-item-children-itm-no-hover {
  display: flex;
  align-items: center;
  padding: 3px 0px;
  padding-right: 30px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  min-width: max-content;
}

.map-item-children-itm-kwrdh {
  display: flex;
  align-items: center;
  padding: 3px 0px;
  padding-right: 30px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  min-width: max-content;
  background-color: rgba(255, 255, 0, 0.5);
}

.map-item-children-itm:hover {
  background-color: rgba(135, 206, 250, 0.4);
}

.map-item-children-itm p {
  white-space: nowrap;
}

.initial-g-line {
  height: 20px;
  width: 2px;
  background-color: #aaa;
  position: absolute;
  top: -6px;
  left: -6px;
  transform: rotate(-40deg);
}

.initial-g-line-modal {
  height: 30px;
  width: 2px;
  background-color: #aaa;
  position: absolute;
  top: -11px;
  left: -6px;
  transform: rotate(-40deg);
}

.initial-g-line-subs {
  height: 40px;
  width: 2px;
  background-color: #aaa;
  position: absolute;
  top: -20px;
  left: -9px;
  transform: rotate(-40deg);
}

.g-line-parent {
  position: relative;
}

.g-line {
  height: 100%;
  width: 2px;
  background-color: #aaa;
  position: absolute;
  top: 15px;
  left: 4px;
}

.dot-g-line {
  font-size: 10px !important;
  margin-right: 8px;
  z-index: 1;
}

.transfer_children_tree {
  margin-top: 3px;
  height: 15px;
  width: 20px;
  position: relative;
  /* background-color: #aaa; */
}

.dot_children_tree {
  height: 6px;
  width: 6px;
  color: #aaa;
  position: absolute;
  bottom: 0;
  left: 4px;
}

.vr_children_tree {
  height: 14px;
  width: 2px;
  background-color: #aaa;
  position: absolute;
  top: 0;
  left: 6px;
}
.filtered_list_filter {
  padding-left: 4px;
  border-radius: 4px;
}

.filtered_list_filter:hover {
  background-color: rgba(135, 206, 250, 0.4);
}

.fs_notificator {
  height: 100%;
  color: #aaa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.fs_notificator_icon {
  font-size: 4rem;
}

.fs_notificator_txt {
  margin-top: 8px;
}

/* Mapper Correction Style goes here */
.box_map_correction {
  /* background-color: rgba(135, 206, 250, 0.3); */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box_map_correction_tittle {
  padding: 8px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  text-decoration: underline;
}

.box_map_correction_body {
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 2px auto 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.box_map_correction_card {
  height: 120px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 2.4px 2.4px 3.2px;
  width: 40%;
  margin: 10px;
  /* transition: all 0.2s ease-in-out; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
}

.bmcc_show {
  justify-content: flex-start;
}

.bmcc_header {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.bmcc_body {
  height: 100%;
  width: 100%;
  /* background-color: #f1f1f1; */
  margin-bottom: 20px;
  padding-top: 20px;
  overflow-y: auto;
  border-top: 2px solid #aaa;
}

.bmcc_body_hide {
  display: none;
}

.bmcc_hvr:hover {
  scale: 1.01;
}

.bmcc_absolute {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0px;
  background-color: #fff;
  z-index: 99;
}

.bmcc_title p {
  font-size: 20px;
  margin-bottom: 0px;
}

.bmcc_title span {
  font-size: 12px;
  color: #aaa;
  font-style: italic;
}

.bmcc_tree_container {
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0px 2px;
  list-style: none;
}

.bmcc_tree_item {
  padding: 4px;
  margin: 0px 2px;
  position: relative;
}

.bmcc_hline {
  position: absolute;
  top: 24px;
  left: -52px;
  height: 2px;
  width: 40px;
  background-color: #aaa;
  z-index: -1;
}

.bmcc_vline {
  height: 100%;
  width: 2px;
  background-color: #aaa;
  position: absolute;
  left: -53px;
  top: 17px;
  z-index: -1;
}

.bmcc_vline_cutter {
  height: calc(100% - 20px);
  width: 2px;
  background-color: #fff;
  position: absolute;
  left: -53px;
  top: 26px;
  z-index: -1;
}

.bmcc_vline_parent {
  height: calc(100% - 40px);
  width: 2px;
  background-color: #aaa;
  position: absolute;
  top: 20px;
  left: -12px;
  z-index: -1;
}

.bmcc_klist_container {
  height: 0px;
  width: 100%;
  background-color: #eee;
  margin-top: 8px;
  border-radius: 8px;
  padding-bottom: 0px;
  /* display: none; */
  transition: all 0.3s ease-in-out;
  overflow: auto;
}

.bmcc_klist_container_show {
  /* display: block; */
  padding-bottom: 8px;
  height: 200px;
}

.bmcc_tree_dot_root {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background-color: green;
  position: absolute;
  top: 20px;
  left: -16px;
}

.bmcc_tree_dot_subs {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background-color: rgb(235, 235, 11);
  position: absolute;
  top: 20px;
  left: -16px;
}

.hover_to_show_parent .target_to_show_child {
  visibility: hidden;
}

.hover_to_show_parent:hover .target_to_show_child {
  visibility: visible;
}

.bmcc_item_to_check {
  margin: 8px;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
  display: flex;
}

.modal-image-viewer {
  max-width: 800px !important;
}

.focus_item_to_check {
  background-color: rgba(255, 255, 0, 0.2);
  border-radius: 8px;
}

.image_process_inidcator {
  height: 30px;
  width: 30px;
  /* background-color: aquamarine; */
  position: relative;
}

.image_process_inidcator_circle {
  font-size: 6px;
  position: absolute;
  top: 12%;
  left: 10%;
  color: #888;
  height: 25px;
  width: 25px;
}

.image_process_inidcator_img {
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}
